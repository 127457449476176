import Swal from "sweetalert2/dist/sweetalert2.js";
import { ElNotification } from 'element-plus';


export default function () {

    function showNotificationAlert(distribuidor,title, cliente, placa, goTo){	

        var audio = new Audio('./sound/sound.mp3');
        audio.play();

          ElNotification({
            title: title,
            message: cliente + "<br>" + placa,
            dangerouslyUseHTMLString: true,
            type: 'info',
            duration: 30000,
            customClass: "notificacaoToast",
            onClick: goTo
            });
            
            const url = `./distribuidor/icone_${distribuidor}.png`;
            var notification = new Notification(title, { body: "cliente: "+cliente+" placa:"+placa, icon: url });

            notification.onclick = function(event) {
                event.preventDefault(); // prevent the browser from focusing the Notification's tab
                window.focus();
                goTo();
              }
    
    };


    function showConfirmAlert(title, icon = "success", text = "", confirmButtonText = "Ok"){						
        const Toast = Swal.mixin({
            position: 'top-end',
            showConfirmButton: true,
            customClass: {
                confirmButton: "bg-none"
            },
            confirmButtonText,
            focusConfirm: false,
        });

        Toast.fire({
            icon,
            title,
            text
        });
    };

    function showTimeAlert(title, icon = "success"){							
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: true,
            timer: 4000,
            timerProgressBar: true,

            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
        });

        Toast.fire({
            icon,
            background: '$cor_indicadores_1',
            title
        });
    };


    return { showConfirmAlert, showTimeAlert, showNotificationAlert };
}